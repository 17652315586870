class Elementor_Building_Carousel{
    static instance;

    static getInstance() {
        if (!Elementor_Building_Carousel.instance) {
            Elementor_Building_Carousel.instance = new Elementor_Building_Carousel();
        }
        return Elementor_Building_Carousel.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-building_carousel.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel');

            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));

                let $image = $scope.find('.elementor-image-framed');
                let $content = $scope.find('.elementor-content-item');
                let $contentactive = $scope.find('.owl-item.active .elementor-content-item');
                let $id = $contentactive.data('trigger');
                $image.fadeOut();
                if($id.length > 0) {
                    $($id).fadeIn().addClass('active');
                }

                $carousel.on('changed.owl.carousel', function (event) {
                    $($image).fadeOut().removeClass('active');
                    // $($content).fadeOut().removeClass('active');

                    let current = event.item.index;
                    let $id = $(event.target).find(".owl-item").eq(current).find(".elementor-content-item").data('trigger');
                    if($id.length > 0) {
                        $($id).fadeIn().addClass('active');
                    }
                });
            }
        } );
    }
}


Elementor_Building_Carousel.getInstance();